import BHGroup_logo from "./Images/BHGroup_logo.png";
import BHFashion_logo from "./Images/BHFashion_logo.png";
import BHTech_logo from "./Images/BHTech_logo.png";
import facebook_logo from "./Images/socialMedia/facebook_logo.png";
import twitter_logo from "./Images/socialMedia/twitter_logo.png";
import instagram_logo from "./Images/socialMedia/instagram_logo.png";

function App() {
  const openUrl = (urlToOpen: string) => {
    const popup_window = window.open(urlToOpen);
    try {
      popup_window?.focus();
    } catch (e) {
      alert(
          'Please disable your pop-up blocker and click the "Open" link again.'
      );
    }
  };
  
  return (
      <div className="flex flex-col items-center text-center">
        <header className="flex flex-col items-center bg-white mb-10">
          <img src={BHGroup_logo} className="w-80 h-80 my-5" alt="logo" />
          <p className="text-5xl font-bold font-courier max-w-4xl">
            Welcome to BackHome Group
          </p>
        </header>
        <div className="flex justify-center">
          <p className="flex text-2xl font-normal font-courier mb-10 max-w-4xl">
            This is the entry point to BackHome Group subsidiaries. We
            will keep updating the options as more of our products and
            services come online. Thanks for visiting as you follow us or go to the subsidiary you are interested in.
          </p>
        </div>
        <div className="flex flex-row gap-x-5 mb-40 items-center justify-center">
          <div className="flex flex-col items-center border border-gray-300 bg-gray-50 rounded p-2 w-96">
            <img
                src={BHFashion_logo}
                alt="BackHome Fashion Logo"
                className="w-40 h-40"
            />
            <button
                type="button"
                className="border rounded-full p-2 bg-blue-200 border-blue-300 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50"
                onClick={() => openUrl("https://www.backhomefashion.com/")}
            >
              BackHome Fashion
            </button>
            <p className="mt-4 text-xs">Follow BackHome Fashion!</p>
            <div className="flex justify-between items-center w-8/12">
              <img
                  src={facebook_logo}
                  alt="BackHome Fashion Logo"
                  className="w-14 h-8 cursor-pointer hover:bg-gray-200 rounded-3xl"
                  onClick={() =>
                      openUrl("https://www.facebook.com/BackHomeFashion/")
                  }
              />
              <img
                  src={instagram_logo}
                  alt="BackHome Fashion Logo"
                  className="w-10 h-6 cursor-pointer hover:bg-gray-200 rounded-full"
                  onClick={() =>
                      openUrl("https://www.instagram.com/backhome_fashion/")
                  }
              />
              <img
                  src={twitter_logo}
                  alt="BackHome Fashion Logo"
                  className="w-9 h-9 cursor-pointer hover:bg-gray-200 rounded-full"
                  onClick={() => openUrl("https://twitter.com/backhome_style")}
              />
            </div>
          </div>
          <div className="flex flex-col items-center border border-gray-300 bg-gray-50 rounded p-2 w-96">
            <img
                src={BHTech_logo}
                alt="BackHome Fashion Logo"
                className="w-52 h-40"
            />
            <button
                type="button"
                className="border rounded-full p-2 bg-blue-200 border-blue-300 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50"
                onClick={() => openUrl("https://www.backhometech.com/")}
            >
              BackHome Technologies
            </button>
            <p className="mt-4 text-xs">Follow BackHome Technologies!</p>
            <div className="flex justify-between items-center w-8/12">
              <img
                  src={facebook_logo}
                  alt="Facebook Logo"
                  className="w-14 h-8 cursor-pointer hover:bg-gray-200 rounded-3xl"
                  onClick={() =>
                      openUrl("https://www.facebook.com/backhome.technologies")
                  }
              />
              <img
                  src={instagram_logo}
                  alt="Instagram Logo"
                  className="w-10 h-6 cursor-pointer hover:bg-gray-200 rounded-full"
                  onClick={() =>
                      openUrl("https://www.instagram.com/backhome_technologies/")
                  }
              />
              <img
                  src={twitter_logo}
                  alt="Twitter Logo"
                  className="w-9 h-9 cursor-pointer hover:bg-gray-200 rounded-full"
                  onClick={() => openUrl("https://twitter.com/BackHome_Tech")}
              />
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
